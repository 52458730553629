.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bordered-nav-profile::before {
  content: "";
  position: absolute;
  top: 50%;
  translate: 0 -50%;
  left: -8px;
  height: 70%;
  width: 4px;
  background-color: #a51535;
  border-radius: 2px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.custom-datepicker {
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid #a51535;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  padding: 8px;
  z-index: 1000;
}

.custom-datepicker:focus,
.custom-datepicker:hover {
  border-color: #a51535;
  box-shadow: 0 0 0 1px #a51535;
}

.custom-datepicker-dropdown .ant-picker-cell:not(.ant-picker-cell-disabled) {
  background-color: #f6e8eb;
  color: #494e50;
}

.custom-datepicker-dropdown
  .ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-color: #a51535 !important;
}

.custom-datepicker-dropdown .ant-picker-cell-selected {
  background-color: #a51535 !important;
  color: #494e50;
}
.custom-datepicker-dropdown .ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: #a51535 !important;
  color: #ffffff;
}

.custom-datepicker-dropdown .ant-picker-cell-inner:hover {
  background-color: #8c0926;
  color: #ffffff;
}
